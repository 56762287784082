/**
 * This is an implementation of bootstrap 4's grid system
 * using styled components.
 */
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

export const ContainerFluid = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
`

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${props => props.theme.widths.breakpoints.sm}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.widths.breakpoints.md}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.widths.breakpoints.lg}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.widths.breakpoints.xl}) {
    max-width: 1140px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  ${props => (props.align ? `align-items: ${props.align}` : "")}
  ${props => (props.justify ? `justify-content: ${props.justify}` : "")}
`

Row.propTypes = {
  /**
   * This is the value for the css property align-items.
   */
  align: PropTypes.string,
  /**
   * This is the value for the css property justify-content.
   */
  justify: PropTypes.string
}

const autoStyles = css`
  flex: 1 0 0;
  max-width: 100%;
`

export const Column = styled.div`
  ${props => {
    let styles = `
      padding-right: 15px;
      padding-left: 15px;
      position: relative;
      width: 100%;
      min-height: 1px;
    `

    if (props.auto) {
      const generalStyles = `
        width: auto;
      `

      return styles + generalStyles
    }

    if (props.xs) {
      let breakpointStyles = ""

      if (props.xs === "auto") {
        breakpointStyles = autoStyles
      } else {
        const maxWidth = (props.xs / 12) * 100

        breakpointStyles = `
          flex: 0 0 ${maxWidth}%;
          max-width: ${maxWidth}%;
        `
      }

      styles += breakpointStyles
    }

    if (props.sm) {
      let breakpointStyles = ""

      if (props.sm === "auto") {
        breakpointStyles = autoStyles
      } else {
        const maxWidth = (props.sm / 12) * 100

        breakpointStyles = `
          flex: 0 0 ${maxWidth}%;
          max-width: ${maxWidth}%;
        `
      }

      styles += `
        @media (min-width:${props.theme.widths.breakpoints.sm}) {
          ${breakpointStyles}
        }
      `
    }

    if (props.md) {
      let breakpointStyles = ""

      if (props.md === "auto") {
        breakpointStyles = autoStyles
      } else {
        const maxWidth = (props.md / 12) * 100

        breakpointStyles = `
          flex: 0 0 ${maxWidth}%;
          max-width: ${maxWidth}%;
      `
      }

      styles += `
        @media (min-width:${props.theme.widths.breakpoints.md}) {
          ${breakpointStyles}
        }
      `
    }

    if (props.lg) {
      let breakpointStyles = ""

      if (props.lg === "auto") {
        breakpointStyles = autoStyles
      } else {
        const maxWidth = (props.lg / 12) * 100

        breakpointStyles = `
          flex: 0 0 ${maxWidth}%;
          max-width: ${maxWidth}%;
        `
      }

      styles += `
        @media (min-width:${props.theme.widths.breakpoints.lg}) {
          ${breakpointStyles}
        }
      `
    }

    if (props.xl) {
      let breakpointStyles = ""

      if (props.xl === "auto") {
        breakpointStyles = autoStyles
      } else {
        const maxWidth = (props.xl / 12) * 100

        breakpointStyles = `
          flex: 0 0 ${maxWidth}%;
          max-width: ${maxWidth}%;
        `
      }

      styles += `
        @media (min-width:${props.theme.widths.breakpoints.xl}) {
          ${breakpointStyles}
        }
      `
    }

    return styles
  }};
`

Column.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
