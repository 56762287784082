import styled, { css } from "styled-components"
import ReactPhoneNumberInput from "react-phone-number-input"
import { Row, Column } from "../Grid"
import { Label as Tag } from "../Global"

export const RadioContainer = styled.div`
  display: block;
  position: relative;
`

export const CheckboxContainer = styled.div`
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
`

export const Label = styled.label`
  display: inline-block;
  font-weight: 700;
  margin-bottom: 5px;
  max-width: 100%;

  ${props => (props.required ? `&:after {content: " *";}` : null)}

  ${RadioContainer} &, ${CheckboxContainer} & {
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;
    min-height: 20px;
    padding-left: 20px;
  }
`

export const HiddenFileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus + label {
    outline: 5px auto -webkit-focus-ring-color;
    text-decoration: none;
  }
`

export const FileInputLabel = styled.label`
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  font-weight: 400;

  color: #333;
  background-color: #fff;
  border-color: #bfbfbf #bfbfbf #b3b3b3;
  text-shadow: 0 1px 1px #e6e6e6;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="white", endColorstr="#f2f2f2");
  background-image: linear-gradient(white, #f2f2f2);

  &:hover,
  &:focus,
  &:active {
    color: #333;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #b3b3b3;
    text-decoration: none;
  }

  &:active {
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &:active:hover,
  &:active:focus {
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }

  ${props => {
    let styles = ""

    if (props.size === "sm") {
      styles += `
          padding: 5px 10px;
          font-size: 12px;
          line-height: 1.5;
          border-radius: 3px;
        `
      // This is not an option in bootstrap but we use this kind of padding for panel buttons
    } else if (props.size === "xs") {
      styles += `
        padding: 2px 6px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
      `
    } else {
      styles += `
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857;
        border-radius: 2px;
      `
    }

    return styles
  }}
`

export const StyledRadio = styled.input`
  box-sizing: border-box;
  padding: 0;
  line-height: normal;
  margin: 4px 0 0;
  margin-left: -20px;
  margin-top: 4px\9;
  position: absolute;

  ${RadioContainer} & {
    position: absolute;
    margin-left: -20px;
  }
`

const inputStyles = css`
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }

  &[disabled] {
    background-color: #eeeeee;
    opacity: 1;
    cursor: not-allowed;
  }

  ${props => {
    if (props.as === "textarea") {
      return `
        height: auto;
      `
    }

    return ""
  }}
`

export const StyledInput = styled.input`
  ${inputStyles}
`

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const InputGroupAddon = styled.span`
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;

  ${InputGroup} &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  ${InputGroup} &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
`

// Based on Bootstrap's Form Control element
export const FormControl = styled(StyledInput)`
  width: 100%;

  ${InputGroup} & {
    flex: 1 1 auto;
    width: 1%;
  }

  ${InputGroup} &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${InputGroup} &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${InputGroup} &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  ${props => {
    if (props.size === "lg") {
      return `
        select.& {
          height: 46px;
          line-height: 46px;
        }

        height: 46px;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
      `
    }
    if (props.size === "sm") {
      return `
        select.& {
          height: 30px;
          line-height: 30px;
        }

        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
      `
    }
  }}
`

export const HelpBlock = styled.span`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;

  ${props => (props.small ? `font-size: 12px;` : null)}

  ${props => (props.muted ? `color: #777777` : null)}
`

export const FormGroup = styled.div`
  margin-bottom: 15px;

  & ${FormControl} {
    ${props =>
      props.error
        ? `
          border-color: #a94442;
          -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        `
        : null}

    ${props =>
      props.inline
        ? `
          display: inline-block;
          width: auto;
        `
        : null}
  }

  & ${InputGroupAddon} {
    ${props =>
      props.error
        ? `
          background-color: #f2dede;
          border-color: #a94442;
          color: #a94442;
        `
        : null}
  }

  & ${HelpBlock} {
    ${props => (props.error ? `color: #a94442;` : null)}
  }

  & ${Label} {
    ${props => (props.error ? `color: #a94442;` : null)}
  }
`

export const FormRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`

export const FormColumn = styled(Column)`
  padding-left: 5px;
  padding-right: 5px;
`

/**
 * We also import the styles for the react-phone-number-input component
 * at the top of the file. We mostly overwrite a few here to make it look like our own inputs
 */
export const StyledPhoneInput = styled(ReactPhoneNumberInput)`
  position: relative;
  flex: 1;

  & .PhoneInputCountry {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin-right: 12px;
    margin-left: 12px;
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  & .PhoneInputCountryIcon {
    /* The flag icon size is 4x3 hence the exact width and height values */
    width: 20px;
    height: 15px;

    /* Makes sure width x height is exactly 4x3 and border width is not included in it */
    box-sizing: content-box;
  }

  & .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  & .PhoneInputInternationalIconGlobe {
    opacity: 0.65;
  }

  & .PhoneInputInternationalIconPhone {
    opacity: 0.8;
  }

  & .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  }

  & .PhoneInputCountrySelectArrow {
    display: block;
    content: "";
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }

  & {
    .PhoneInputCountrySelect:focus
      + .PhoneInputCountryIcon
      + .PhoneInputCountrySelectArrow {
      color: #66afe9;
    }

    .PhoneInputCountrySelect:focus
      + .PhoneInputCountryIcon
      .PhoneInputInternationalIconGlobe {
      color: #66afe9;
    }
  }

  & .PhoneInputInput {
    ${inputStyles}
    width: 100%;
    flex: 1 1;
    min-width: 0;
    padding-left: 57px;
  }
`

export const datePickerStyles = props => ({
  container: base => ({
    ...base
  }),
  input: base => ({
    ...base,
    padding: 0,
    margin: 0
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0
  }),
  control: (base, state) => {
    const additionalStyles = {}

    if (props.size === "sm") {
      additionalStyles.height = "30px"
      additionalStyles.padding = "5px 10px"
      additionalStyles.fontSize = "12px"
      additionalStyles.borderRadius = "3px"
    } else if (props.size === "lg") {
      additionalStyles.height = "46px"
      additionalStyles.padding = "10px 16px"
      additionalStyles.fontSize = "18px"
      additionalStyles.borderRadius = "3px"
    }

    return {
      backgroundColor: "#fff",
      height: "34px",
      padding: "6px 12px",
      fontSize: "14px",
      lineHeight: 1.42857,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: state.isFocused ? "#66afe9" : "#ccc",
      borderRadius: "2px",
      boxShadow: state.isFocused
        ? "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)"
        : "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
      transition:
        "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;",
      ...additionalStyles
    }
  }
})

export const StyledPhoneExtensionInput = styled(StyledInput)`
  width: 60px;
`

export const DropZone = styled.div`
  position: relative;
`

export const DropOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  & div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px dashed #fff;
  }

  & div p {
    color: #fff;
    font-weight: normal;
    text-align: center;
    font-size: 30px;
  }
`

export const TextAreaContainer = styled.div`
  display: block;
  position: relative;
`

export const MaxLength = styled.div`
  position: absolute;
  left: 50%;
  bottom: -10px;

  & ${Tag} {
    position: relative;
    left: -50%;
    display: block;
  }
`
