import React from "react"
import PropTypes from "prop-types"
import { StyledPhoneInput, StyledPhoneExtensionInput } from "./style"

/**
 * PhoneInput is a component for entering phone numbers. It's composed of a country selector and a text input.
 * It formats the phone number according to the selected country.
 * While the phone number is displayed (and typed) in the country or international format, the underlying value is expressed in E.164 format.
 * At the moment this component is an abstraction over react-phone-number-input
 * @see https://github.com/catamphetamine/react-phone-number-input
 */
export function PhoneInput({
  defaultCountry = "US",
  value,
  onChange,
  ...props
}) {
  return (
    <StyledPhoneInput
      {...props}
      value={value}
      onChange={onChange}
      defaultCountry={defaultCountry}
    />
  )
}

PhoneInput.propTypes = {
  /**
   * The initial country to use in the country picker specified as a two letter code. The default is US.
   * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 for all available country codes
   */
  defaultCountry: PropTypes.string,
  /**
   * The phone number in E.164 format.
   * @see https://en.wikipedia.org/wiki/E.164
   */
  value: PropTypes.string,
  /**
   * The on change callback. It receives the new phone number in E.164 format.
   */
  onChange: PropTypes.func.isRequired
}

export function PhoneExtensionInput({ value, onChange, ...props }) {
  function handleChange(e) {
    // Only allow numeric input in the extension field
    if (/\D/.test(e.target.value)) {
      return
    }

    onChange(e.target.value)
  }

  return (
    <StyledPhoneExtensionInput
      {...props}
      type="tel"
      onChange={handleChange}
      value={value}
    />
  )
}

PhoneExtensionInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}
