// Style info that can be imported or used with the ThemeProvider.
//
// @example
//
// import {ThemeProvider} from "styled-components"
// import theme from "../src/theme"
// const Button = styled.button`
//   font-size: 1.1em;
//   margin: 1em;
//   padding: 0.25em 1em;
//   border-radius: 3px;
//   border-style: solid;
//   border-width: 1px;
//   border-color: ${props => props.type === 'success' ? props.theme.colors.brandSuccess : props.theme.colors.grayLight};
//   background-color: ${props => props.type === 'success' ? props.theme.colors.brandSuccess : props.theme.colors.grayLighter}
//   color: ${props => props.theme.colors.grayDark}
// `
// const Widget = props => {
// return (
//   <ThemeProvider theme={theme}>
//     <Button type="success">Push me!</Button>
//   </ThemeProvider>
//   )
// }

// TODO: refresh/update from https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss

const $white = "#fff",
  $grayBase = "#000",
  $grayDarker = "#222",
  $grayDark = "#333",
  $gray = "#555",
  $grayLight = "#777",
  $grayLighter = "#eee",
  $blue = "#0d6efd",
  $indigo = "#6610f2",
  $purple = "#6f42c1",
  $pink = "#d63384",
  $red = "#dc3545",
  $orange = "#fd7e14",
  $yellow = "#ffc107",
  $green = "#28a745",
  $teal = "#20c997",
  $cyan = "#17a2b8",
  $brandPrimary = "#337ab7",
  $brandSuccess = "#5cb85c",
  $brandInfo = "#5bc0de",
  $brandWarning = "#f0ad4e",
  $brandDanger = "#d9534f",
  $rlPaleGrey = "#edeff5",
  $rlLightBlueGray = "#cfd4e4",
  $breakpointsWidths = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px"
  },
  $containerMaxWidths = {
    sm: "540px",
    md: "720px",
    lg: "960px",
    xl: "1140px"
  }

export const colors = {
  white: $white,
  grayBase: $grayBase,
  grayDarker: $grayDarker,
  grayDark: $grayDark,
  gray: $gray,
  grayLight: $grayLight,
  grayLighter: $grayLighter,
  greyBase: $grayBase,
  greyDarker: $grayDarker,
  greyDark: $grayDark,
  grey: $gray,
  greyLight: $grayLight,
  greyLighter: $grayLighter,

  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,

  brandPrimary: $brandPrimary,
  brandSuccess: $brandSuccess,
  brandInfo: $brandInfo,
  brandWarning: $brandWarning,
  brandDanger: $brandDanger,

  rlPaleGray: $rlPaleGrey,
  rlLightBlueGray: $rlLightBlueGray,

  defaultText: $grayDarker,
  mutedText: $grayLight,

  stateSuccessText: "#3c763d",
  stateSuccessBg: "#dff0d8",
  stateSuccessBorder: "#d6e9c6", // darken(adjust-hue($state-success-bg, -10), 5%) !default;

  stateInfoText: "#31708f",
  stateInfoBg: "#d9edf7",
  stateInfoBorder: "#bce8f1", // darken(adjust-hue($state-info-bg, -10), 7%) !default;

  stateWarningText: "#8a6d3b",
  stateWarningBg: "#fcf8e3",
  stateWarningBorder: "#faebcc", // darken(adjust-hue($state-warning-bg, -10), 5%) !default;

  stateDangerText: "#a94442",
  stateDangerBg: "#f2dede",
  stateDangerBorder: "#ebccd1", // darken(adjust-hue($state-danger-bg, -10), 5%) !default;

  iconDefault: $grayLighter,
  iconPrimary: $brandPrimary,
  iconSuccess: $brandSuccess,
  iconInfo: $brandInfo,
  iconWarning: $brandWarning,
  iconDanger: $brandDanger
}

export const widths = {
  breakpoints: $breakpointsWidths,
  containerMaxWidths: $containerMaxWidths
}

const panelStyles = {
  bgColor: $rlPaleGrey,
  borderColor: $rlLightBlueGray
}

export default {
  colors,
  panel: panelStyles,
  colours: colors,
  widths
}
