import React from "react"
import { string, any, bool, node, func } from "prop-types"
import styled from "styled-components"

/**
 * Provide a standard horizontally-oriented checkbox for Kickserv forms. The label for the checkbox is to the right of box.
 *
 * @function
 *
 * @notes
 *
 * This uses the styled-components ThemeProvider, so make sure it's inside that. (See App and AppProvider)
 *
 * This should be used in some sort of form
 *
 * @example
 *
 *   const Example = ({ onSubmit }) => {
 *     const { fields, setField, handleChange } = useFormFields
 *     function handleSubmit(ev) {
 *       ev.stopPropagation()
 *       ev.preventDefault()
 *       onSubmit(fields)
 *     }
 *     return (
 *       <MyForm onSubmit={handleSubmit}>
 *         <Checkbox
 *           name="optin"
 *           checked={fields.optin}
 *           onChange={handleChange} >
 *             Do you want to opt in for email?
 *         </Checkbox>
 *         <SubmitButton/>
 *       </MyForm>
 *     )
 *   }
 */
export default function Checkbox({
  name,
  checked,
  value,
  disabled = false,
  bold = false,
  children = null,
  defaultChecked,
  onChange,
  ...labelProps
}) {
  return (
    <CheckboxLabel disabled={disabled} bold={bold} {...labelProps}>
      <CheckboxInput
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled ? "disabled" : null}
        value={value}
        defaultChecked={defaultChecked}
      />{" "}
      {children}
    </CheckboxLabel>
  )
}

Checkbox.propTypes = {
  /**
   * the name provided to the `name` field on the checkbox.
   * This is needed when using the useFormField hook to handle inputs state
   */
  name: string,
  /**
   * whether the checkbox is checked or not.
   */
  checked: bool,
  /**
   * the value of the checkbox. Useful for form submissions with multiple checkboxes
   * Use the checked prop instead of this.
   */
  value: any,
  /**
   * whether the field is disabled. This will be checked for truthyness and falsyness, so can be anything
   */
  disabled: any,
  /**
   * whether the label uses bold text.
   */
  bold: bool,
  /**
   * Used to supply the label contents. It can be any sort of child node, or nothing.
   */
  children: node,
  /**
   * The callback function when the input checkbox changes value
   */
  onChange: func,

  defaultChecked: bool
}

export const CheckboxInput = styled.input`
  vertical-align: top;
`

export const CheckboxLabel = styled.label`
  white-space: ${props => (props.wrap ? props.wrap : "nowrap")};
  display: block;
  font-size: 14px;
  line-height: 1.42857;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: ${props => (props.bold ? "700" : "400")};
  &[disabled] {
    color: ${props => props.theme.colors.mutedText};
  }
`
