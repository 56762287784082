/**
 * We should export all form components here (Inputs, Select, Radio, Checkbox, etc)
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { NativeTypes } from "react-dnd-html5-backend"
import { useDrop } from "react-dnd"
import {
  RadioContainer,
  Label,
  FileInputLabel,
  HiddenFileInput,
  StyledRadio,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  FormRow,
  FormColumn,
  FormControl,
  TextAreaContainer,
  HelpBlock,
  datePickerStyles,
  DropZone,
  DropOverlay,
  MaxLength
} from "./style"
import { DatePicker } from '@atlaskit/datetime-picker'
import { PhoneInput, PhoneExtensionInput } from "./PhoneInput"
import Checkbox from "./Checkbox"
import { MutedText, Label as Tag } from "../Global"

export function Radio({ children, ...props }) {
  return (
    <RadioContainer>
      <Label>
        <StyledRadio type="radio" {...props} /> {children}
      </Label>
    </RadioContainer>
  )
}

Radio.propTypes = {
  children: PropTypes.node
}

export function DateInput({ value, onChange, size, ...otherProps }) {
  return (
    <DatePicker
      {...otherProps}
      value={value}
      onChange={onChange}
      selectProps={{
        "aria-label": "date",
        styles: datePickerStyles({ size })
      }}
      hideIcon
    />
  )
}

export function TextArea({
  onFocus,
  onBlur,
  maxLength,
  value,
  showCharacterCount = false,
  ...inputProps
}) {
  const [focused, setFocused] = useState(false)

  function handleFocus(e) {
    setFocused(true)
    onFocus && onFocus(e)
  }

  function handleBlur(e) {
    setFocused(false)
    onBlur && onBlur(e)
  }

  return (
    <TextAreaContainer>
      <FormControl
        {...inputProps}
        as="textarea"
        maxLength={maxLength}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {showCharacterCount && focused && maxLength && value?.length ? (
        <MaxLength>
          <Tag appearance="primary">
            {value.length}/{maxLength}
          </Tag>
        </MaxLength>
      ) : null}
    </TextAreaContainer>
  )
}

TextArea.propTypes = {
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  showCharacterCount: PropTypes.bool
}

DateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["sm", "lg"])
}

export function FileInput({ id, size, onChange, children, ...inputProps }) {
  const [files, setFiles] = useState(null)

  function handleChange(e) {
    setFiles(e.target.files)

    onChange && onChange(e)
  }

  return (
    <>
      <HiddenFileInput
        id={id}
        type="file"
        {...inputProps}
        onChange={handleChange}
      />
      <FileInputLabel as="label" htmlFor={id} size={size}>
        {children}
      </FileInputLabel>
      {files && files.length > 0 ? (
        <>
          {" "}
          <MutedText small>
            {files.length > 1 ? `${files.length} files` : files[0].name}
          </MutedText>
        </>
      ) : null}
    </>
  )
}

FileInput.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node
}

export function FileDropZone({ onDrop, disabled = false, children }) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        const files = monitor.getItem().files

        onDrop(files)
      }
    },
    canDrop: () => {
      return !disabled
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  const isActive = canDrop && isOver

  return (
    <DropZone ref={drop}>
      {children}
      {isActive ? (
        <DropOverlay>
          <div>
            <p>Drop files to upload</p>
          </div>
        </DropOverlay>
      ) : null}
    </DropZone>
  )
}

FileDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  // Is the file drop zone disabled
  // Similar to the disabled prop on regular inputs
  disabled: PropTypes.bool,
  children: PropTypes.node
}

// Export these additional styling components
// These are based on bootstrap components
// We alias label to ControlLabel to be compatible with react-bootstrap's naming
export {
  Label as ControlLabel,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  FormRow,
  FormColumn,
  FormControl,
  Checkbox,
  PhoneInput,
  PhoneExtensionInput,
  HelpBlock
}
