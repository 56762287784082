import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "../../src/theme/index"
import { PhoneInput } from "../../src/components/Forms"

const StyledPhoneInput = styled(PhoneInput)`
  & .PhoneInputInput {
    display: block;
    width: 100%;
    border: 0;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    transition: 0.2s;

    font-size: 18px;
    line-height: 1.15;
    height: auto;
    padding: 0.75rem;
    padding-left: 54px;

    &:focus {
      outline: 0;
      background-color: rgba(255, 255, 255, 0.25);
      box-shadow: none;
    }

    &::placeholder {
      --text-opacity: 0.75;
      color: rgba(255, 255, 255, var(--text-opacity));
    }
  }

  & .PhoneInputCountrySelectArrow {
    color: #ffffff;
    opacity: 0.8;
  }

  & .PhoneInputCountry {
    padding: 0 6px;
    margin: 0 6px 0 6px;
  }

  &
    .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: #ffffff;
    opacity: 1;
  }
`

function CompanyMobilePhoneInput() {
  const [phoneNumber, setPhoneNumber] = useState("")

  return (
    <ThemeProvider theme={theme}>
      <>
        <StyledPhoneInput
          id="billing_phone_number_field"
          defaultCountry="US"
          value={phoneNumber}
          onChange={value => setPhoneNumber(value)}
          placeholder="Mobile Phone"
        />
        <input
          type="hidden"
          name="account[account_details_attributes][billing_phone_number]"
          value={phoneNumber || ""}
        />
      </>
    </ThemeProvider>
  )
}

export default CompanyMobilePhoneInput
